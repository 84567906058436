export const api = (endpoint, data) => {
    if (data) {
        return fetch(endpoint, {
            method: 'POST',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'Content-Type': 'application/json',
                'X-API-KEY': '1234'
            }),
            body: JSON.stringify(data)
        })
    }
    else {
        return fetch(endpoint, {
            method: 'GET',
            headers: new Headers({
                'Authorization': 'Bearer ' + localStorage.getItem('token'),
                'X-API-KEY': '1234'
            })
        }).then(res => { if (res.status === 401) return {}; return res.json() })
    }
}